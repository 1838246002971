@charset "utf-8";
/* CSS Document */

.fclear {clear:both; float:none; height:0px; overflow:hidden;}

* {box-sizing: border-box;}
*:before, *:after {
    box-sizing: border-box;
}
a:hover
{ opacity:0.75;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
html, body
{ height:100%;
}
body
{ font-family:Arial, Helvetica, sans-serif;
  font-size:13px; color:#7f8d92;
  margin:0;
}
body.login
{ background:url(../images/bg.png) repeat-x center #eff4f6;
}
a, a:hover{text-decoration:none;}
.login-main{
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 8%;
  background-color: #ececec;
}
.loginbg
{ 
  max-width:360px; margin:0 auto 0; text-align:center;
  width: 100%;
}
.login-form-button {
  padding: 0 14px;
  height: 35px;
  background-color: #0069ff;
  border-color: #0069ff;
}
.login-form-button:hover {
  background-color: #d03f1e;
  border-color: #d03f1e;
}
.form-bottom-info .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-label h4 {
  font-size: 13px;
    font-weight: 600;
    color: #000;
    margin: 0 0 17px;
    line-height: normal;
}
.login-form {
  width: 100%;
  padding: 20px 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
}
.login-form .ant-input-affix-wrapper {
  height: 40px;
  border:0 !important;
  padding-left: 0;
    padding-right: 0;
    box-shadow: none !important;
}
.login-form .ant-input-affix-wrapper .ant-input{
  border-bottom:1px solid #e5e5e5;
}
.login-form .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input{
  border-bottom:1px solid #0069ff;
}
.login-form .ant-input-affix-wrapper.ant-input-password {
  position: relative;
}
.login-form .ant-input-affix-wrapper.ant-input-password .ant-input {
  padding-right: 20px;
}
.login-form .ant-input-affix-wrapper.ant-input-password span.ant-input-suffix {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.login-form .ant-input-affix-wrapper .ant-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #555;
}
.login-form .ant-input-affix-wrapper .ant-input::-moz-placeholder { /* Firefox 19+ */
  color: #555;
}
.login-form .ant-input-affix-wrapper .ant-input:-ms-input-placeholder { /* IE 10+ */
  color: #555;
}
.login-form .ant-input-affix-wrapper .ant-input:-moz-placeholder { /* Firefox 18- */
  color: #555;
}
.login-form .ant-input-affix-wrapper .ant-input-prefix{
  margin-right: 10px;
}
.form-bottom-info {
  margin-bottom: 0;
}
.loginlogo
{ padding-top:0; padding-bottom:25px;
}
.login_bott
{ position:fixed; bottom:8px; text-align:center; width:100%;
  color:#8b989d; font-size:12px;
} 
.loginwid
{ max-width:336px; margin:0 auto; 
}
.loginwid .fright
{ color:#c1c8cb;
}
.loginwid a
{ color:#8b989d;
}
.fleft
{ float:left;
}
.fright
{ float:right; 
}
.loginsub1
{ border-bottom:1px solid #e5e5e5; position:relative; margin-bottom:12px;
}
.loginsub1 input
{ border:0; box-shadow:0 0 0 0; width:100%; box-sizing:border-box; padding:10px; color:#8c8c8c;
}
.helpic
{ position:absolute; right:0; top:9px; cursor:pointer;
}
.loginsub3
{ padding-top:10px;
} 
.loginsub3 span
{ float:left;
} 
.loginsub3 label
{ float:right; color:#b1b1b1;
}
.loginsub3 strong
{ color:#7f8d92;
}
.btn-primary
{ padding:6px 16px 6px 16px; background:#0094c9; border:0; text-transform:uppercase; border-radius:4px; margin:1px;
}
.btn-primary:hover
{ background:#f58220;
}
.graybtn
{ background:#9fb4bc;
}
.loginsub3
{ font-size:12px; color:#b1b1b1; text-align:left; 
}
.loginsub3 label
{ line-height:1.25em;
}
.loginsub3 strong
{ font-size:16px;
}
#leftnav 
{ width:182px; float:left; background:#f1f1f1; height:100%; margin-right:25px;
}
#leftnav.closednav
{ width:76px;
}
#leftnav.closednav span
{ display:none;
}
#leftnav.closednav .powered_by
{ display:none;
}
#leftnav ul{list-style:none; padding:0 0 0 0; margin:0; }
#leftnav ul li a{padding:20px 0 20px 20px; display:block; background:#ededed; border-left:7px #ededed solid; font-size:14px; color:#58666d; display:block; line-height:1em;}
#leftnav ul li a img{margin:0 15px 0 0; vertical-align:middle;}
#leftnav ul li a:hover, #leftnav ul li.active a
{ background:#fff; border-left:7px #7bcc03 solid 
}
.powered_by{padding:20px 0 20px 30px; border-top:1px #c7d3d9 solid; color:#b1b1b1; font-size:12px; line-height:20px;}
.powered_by span{color:#7f8d92; font-size:16px; font-weight:bold;}
.rightpart
{ overflow:hidden; margin:0 25px 0 0; padding-bottom:20px;
}
.user_logo{float:left; width:100%; padding:6px 0}
.menu_icon{ display:inline-block; float:left; margin:13px 18px 13px 0 }
.user_login{ display:inline-block; float:left; margin:13px 0}
.logo{ display:inline-block; float:right; width:128px;}
.logo img
{ max-width:100%;
}
.icon_small{
  width: 20px;
  margin-right: 10px;
}

.graybar{float:left; width:100%; padding:10px 10px 10px 10px; border-bottom:1px #c3d5db solid; background:#ededed}
.bluetab{display:inline-block; background:#0074c1; border-bottom:2px #003a60 solid; font-size:18px; color:#fff; font-weight:bold; line-height:20px; width:180px; height:57px; padding:10px 0 0 15px; position:relative;}
.bluetab span{font-size:14px; font-weight:normal; display:block;}
.lightbluetab{display:inline-block; background:#21a8f2; border-bottom:2px #0f82ea solid; font-size:18px; color:#fff; font-weight:bold; line-height:20px; width:180px; height:57px; padding:10px 0 0 15px; margin:0 7px; position:relative;}
.lightbluetab span{font-size:14px; font-weight:normal; display:block;}
.yellowtab{display:inline-block; background:#ffbe07; border-bottom:2px #ff8c01 solid; font-size:18px; color:#fff; font-weight:bold; line-height:20px; width:180px; height:57px; padding:10px 0 0 15px; position:relative;}
.yellowtab span{font-size:14px; font-weight:normal; display:block;}
.add_client{float:right; display:inline-block; margin:17px 10px 0 0}
.add_client a{background:#7bcc03; font-size:13px; color:#ffffff; padding:8px 12px; border-bottom:2px #609f02 solid }
.add_client a:hover{background:#609f02; border-bottom:2px #7bcc03 solid }

.whitebar{float:left; width:100%; padding:20px 0}
.inputtext1, .inputtext2 
{ font-size:15px; color:#48565d; padding:6px 10px; border:0; border-bottom:1px #c3d5db solid; width:177px; margin:0 17px 0 0; vertical-align:middle; 
}
.inputtext2 { width:118px; }

.styledbg {
  position: relative;
  display:inline-block;
  background:url(../images/down-blue1.png) no-repeat right;
  overflow: hidden;
  border-radius:0;
  border-bottom:1px #c3d5db solid;
  width:180px;
  vertical-align:middle;
  margin:0 20px 0 0;
}
.styledbg select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px 15px 6px 10px;
  cursor: pointer;
  background:none;
  font-size:15px; color:#afafaf;
}
.styledbg select::-ms-expand {
  display: none;
}

.search_clear{ display:inline-block; float:right;}
.overflow1
{ overflow:auto; clear:both;
}
.detail_table{ min-width:1120px; } 
.overflow2
{ overflow:auto;
}
.detail_table table{  border:0px !important; border-top:solid 0 #c3d5db !important; border-left:0 !important;  }
.txtcenter {  text-align:center; }
.detail_table td {  vertical-align:top; }
.detail_table td.icons { vertical-align:middle; }
.icons img { cursor:pointer; }
.detail_table th span { cursor:pointer; background:url(../images/down-gray.png) no-repeat right; padding-right:20px; }
.detail_table th span.active {  background:url(../images/down-blue.png) no-repeat right; color:#21a8f2; }
.detail_table th {  border:0px !important; border-bottom:solid 1px #c3d5db !important; border-right:0 !important; padding:15px 10px; background:#eeeeee; color:#7f8d92; font-weight:bold;  }
.detail_table td a{ color:#21a8f2; text-decoration:none; font-size:13px; }
.detail_table td{  border:0px !important; border-bottom:solid 1px #c3d5db !important; border-right:0 !important; padding:10px 10px; color:#7f8d92 }
.detail_table table td img{ margin:0 8px 0 0}
.bluetext{color:#21a8f2 !important}

.user_page img{position:absolute; top:6px; right:6px;}
.user_page .question_icon{float:right; width:auto; margin:12px 10px 0 0}
.user_page .question_icon img{position:inherit}
.client_name{display:inline-block; float:left; width:230px; margin:0 20px 0 0; border-bottom:1px #a2a9ad solid; padding:0 0 8px 0; line-height:24px;}
.client_name span{ font-size:21px; color:#58666d; font-weight:bold; display:block}

.user_detail{float:left; width:100%; margin:20px 0 0 0}
.user_detail_lt{float:left; width:250px; margin:0 20px 0 0;}
.user_detail_lt{overflow: hidden;}

.user_detail_lt h3{font-size:16px; color:#58666d; font-weight:bold;}
.user_detail_lt .nav li{width:100%; background:url(../images/drop_arrow.png) no-repeat left 8px;}
.user_detail_lt .nav li.open{background:url(../images/drop_arrow-open.png) no-repeat left 8px;}
.user_detail_lt .nav li a{background:none; display:block; padding:5px 15px}
.user_detail_lt .nav li.open a{background:none; display:block; padding-bottom:5px}
.user_detail_lt .nav li a img{margin:0 5px 0 0; vertical-align:top}
.user_detail_lt .nav li:hover a{background:none;}
.dropdown-menu{border:0; box-shadow:0 0; margin:0; padding:0 0 0 24px}
.user_detail_lt .nav li.open .dropdown-menu a{background:url(../images/drop_arrow-open.png) no-repeat left 8px;}


.user_per_mn{max-width:1000px; background:#fff; padding:10px; margin:0 auto}
.user_per{border:1px #c3c3c3 solid; float:left; width:100%; padding:10px}
.close_icon{float:left; width:100%; text-align:right; margin:0 0 25px 0}
.add_user_mn{float:left; display:inline-block;}
.question_icon{float:right; display:inline-block;}
.add_user{background: #7bcc03;
    font-size: 13px;
    color: #ffffff;
    padding: 8px 12px;
  border:0;
  cursor:pointer;
    border-bottom: 2px #609f02 solid;}
.user_per_mn .graybar{background:#eff4f7; border-bottom:2px #c7d7dd solid; padding:15px}
.user_per_table tr td{color:#646464; font-weight:bold; font-size:14px;}
.user_per_table tr td span{color:#646464; font-weight:normal; font-size:13px; display:block}
.search_left
{ float:left;
}
@media screen and (max-width: 1400px) 
{
.overflow2sub
{ height:400px;
}
}
@media screen and (max-width: 1359px) 
{
.overflow2sub
{ height:320px;
}
}
@media screen and (max-width: 1279px) 
{
.search_left, .search_clear
{ width:100%; text-align:center;
}
.search_left
{ padding-bottom:10px;
}
.inputtext1
{ width:160px; margin:4px;
}
.inputtext2
{ width:150px; margin:4px;
}
.styledbg
{ margin:4px;
}
.whitebar
{ padding:5px 0 15px;
}
}
@media screen and (max-width: 979px) 
{
#leftnav ul li a
{ padding-left:10px;
}
#leftnav
{ width:162px;
}
#leftnav
{ margin-right:15px;
}
.rightpart
{ margin-right:15px;
}
.graybar
{ text-align:center;
}
.bluetab, .lightbluetab, .yellowtab
{ text-align:left; width:150px; vertical-align:middle;
}
.add_client
{ float:none; margin:10px 0 0 0;
}
.lightbluetab
{ margin-left:0; margin-right:0;
}
.add_client a
{ display:inline-block;
}
.rightpart
{ margin:0 15px; overflow:inherit;
}
#leftnav
{ display:none;
}
#leftnav.closednav
{ display:block;
    margin: 0;
    position: fixed;
    right:0;
  width:93%;
  z-index:999;
}
#leftnav.closednav span
{ display:inline-block;
}
}
@media screen and (max-width: 767px) 
{
#leftnav.closednav
{ width:90%;
}
}
@media screen and (max-width: 639px) 
{
.bluetab, .lightbluetab, .yellowtab
{ width:140px;
}
.inputtext1
{ margin-left:0;
}
.styledbg
{ width:100%; margin-left:0; margin-right:0;
}
.inputtext1
{ width:100%; margin-left:0; margin-right:0;
}
#leftnav.closednav
{ width:88%;
}
}
@media screen and (max-width: 479px) 
{
.loginbg, .login_bott
{ padding:0 15px;
}
.bluetab, .lightbluetab, .yellowtab
{ margin:0 0 2px 0; display:block; width:auto;
}
.inputtext2
{ width:135px;
}
#leftnav.closednav
{ width:84%;
}
}
@media screen and (max-width: 359px) 
{
body.login
{ background:#eff4f6 url(../images/bg.png) repeat-x center 47%;
}
}



/*layout comp css*/


.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/* .trigger:hover {
  color: #1890ff;
} */
.info-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: black !important;
  font-weight: 600;
}
.info-tab .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active{
  border: 1px solid #e5e5e5 !important;
  border-right:0 !important;
  border-left:0 !important;
  
}
.info-tab .ant-tabs-nav-list .ant-tabs-tab{
  border-right:1px solid #e5e5e5 !important;
  margin-top: 0 !important;
}
.info-tab .ant-tabs-ink-bar
{
  background: none !important;
}.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;

}


/* list page css */
.add-button-right{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.ant-table-thead tr th {
  font-weight: 600;
  color: #000;
}
.ant-table-thead tr th .ant-table-column-sorters{
  justify-content: flex-start;
  display: inline-flex;
}
.ant-table-thead tr th .ant-table-column-sorters .ant-table-column-sorter{
  margin-left: 5px;
}
.ant-table-column-sorter-up, .ant-table-column-sorter-down{
  font-size: 13px;
}
.ant-table-thead tr th:before{
  display: none;
}
/*.ant-table-tbody > tr > td{
  color: #000;
} */
.icons-list > .anticon{
  margin-right: 11px;
    font-size: 21px;
    color: #0069ff;
}
.add-button-right button.ant-btn.ant-btn-link {
    text-decoration: underline;
}
.ant-layout-sider{
  background: #f2f2f2;
  height:100vh;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
  background: #f2f2f2; 
}

.ant-menu > li .ant-menu-item-icon{
  color: #657278;
  font-size: 20px;
}
.ant-menu-item > a{
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s
}
.ant-menu-inline-collapsed .ant-menu-item > a{
  opacity: 0;
}
.ant-menu > li .ant-menu-title-content a,
.ant-menu > li span.ant-menu-title-content,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon{
  color: #657278;
}
.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow:before,.ant-menu-submenu-arrow:after{
  background: #657278 !important;  
}
.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item:focus-within,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #fff;
}
.ant-menu > li:hover .ant-menu-title-content a,
.ant-menu > li:hover span.ant-menu-title-content{
  color: #657278;
}
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
  color: #657278 !important;
}
.ant-tooltip-inner a{
  color: #fff;
}
.ant-tooltip-inner > img{
  display: none;
}
.top-level-menu-img {
  width: 22px !important;
  max-width: 22px;
}
.ant-menu > li > span > img{
  max-width: 20px;
  margin-right: 10px;
}
.ant-menu > li > img{
  max-width: 20px;
  margin-right: 10px;
}
.ant-layout-header span.anticon.anticon-menu{
  font-size: 28px;
  line-height:0;
  padding:0 11px 0 16px;
}
.ant-menu-submenu-title, .ant-menu-submenu-title:hover
{
  color: #657278 !important;
  font-weight: bold !important;
}
.site-layout-background{
  border-top: 1px solid #e5e5e5;
  padding:10px 15px 0;
}
header.ant-layout-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.top-right-logo {
  margin-right: 16px;
  position: relative;
}
.top-right-logo img {
  max-width: 200px;
  width: 100%;
  margin-top: -6px;

}
.top-right-logo:before {
  content: attr(data-v-text);
  position: absolute;
  bottom: 4px;
  right: 0;
  line-height: normal;
  font-weight: 600;
}
.top-left-search {
  line-height: normal;
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.global-search-anchor {
  float: left;
  width: 100%;
}
.form-layout-control {
  width: 100%;
  
  
}
.form_content_data {
  max-width: 700px;
}
.search-part-top .form-layout-control{
  display: flex;
  flex-wrap: wrap;
}
.search-part-top .form-layout-control .ant-select.ant-select-show-search{
  width: 100% !important;
}
@media (max-width:1280px){
  .search-part-top .form-layout-control .ant-select.ant-select-show-search{
    width: 100% !important;
  } 
}
.form-layout-control .form-row-block > .ant-row.ant-form-item{
  max-width: 500px;
}
.ant-form-item-control-input-content .ant-input-number{
  width: 100%;
  border: 0;
}
.ant-form-item-control-input-content .ant-input,
.ant-form-item-control-input-content .ant-input-number-input{
  width: 100%;
  border: 0;
  border-bottom: 1px solid #b5b5b5;
}
.ant-form-item-control-input-content .ant-input:focus,
.ant-form-item-control-input-content .ant-input-number-input:focus{
  border-bottom:1px solid #0069ff;
      box-shadow: none;  
}
.ant-input-number-focused,
.ant-input-number-focused .ant-input-number-input{
  box-shadow: none!important;
}
.ant-form-item-has-error .ant-form-item-control-input-content .ant-input,
.ant-form-item-has-error .ant-form-item-control-input-content .ant-input-number-input{
  border-bottom-color: red;
  box-shadow: none;
}
.ant-form-item-control-input-content .ant-select{
  width: 100% !important;
}
#customer-form .ant-form-item-control-input-content .ant-select{
  width: 100% !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  
  border:0;
  border-bottom: 1px solid #b5b5b5;
  box-shadow: none !important;
  outline: none !important;
}
.newSearchBar-Area .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  /* border:0; */
  /* border-bottom: 1px solid #b5b5b5; */
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  height: 36px;
}
.newSearchBar-Area .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  padding-right: 48px;
  color: #918d8d;
  padding-top:2px;
}
.ant-form-item-control-input-content .newSearchBar-Area .ant-input{
  width: 100%;
  /* border: 0; */
  /* border-bottom: 1px solid #b5b5b5; */
  border: 1px solid #D9D9D9 !important;
  border-radius: 8px !important;
  height: 36px !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-bottom: 1px solid #b5b5b5;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-bottom:1px solid #0069ff !important;  
}

.ant-btn{
  padding: 0 14px;
  height: 30px;
}
.ant-btn-primary{
  background-color: #0069ff;
    border-color: #0069ff;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus{
  background-color: #438bf4;
  border-color: #438bf4;
}
.fill-gray{
  background-color: #ededed;
  border-color: #ededed;
  color: #000 ;
}
.fill-gray:hover,
.fill-gray:focus{
  color: #000 !important;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.form-layout-control .ant-btn{
  margin-right: 2.3%;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab{
  margin-left: 0;
  transition: unset;
}
.ant-tabs-nav-list .ant-tabs-tab {
  border: 0 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}
.ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active{
  border: 1px solid #e5e5e5 !important;
  border-bottom:0 !important;
}
.ant-input-affix-wrapper {

  border:0 !important;
  padding-left: 0;
    padding-right: 0;
    box-shadow: none !important;
}
.ant-input-affix-wrapper .ant-input{
  border-bottom:1px solid #e5e5e5 !important;
  height:31px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input{
  border-bottom:1px solid #0069ff !important;
}
.ant-input-affix-wrapper.ant-input-password {
  position: relative;
}
.ant-input-affix-wrapper.ant-input-password .ant-input {
  padding-right: 20px;
}
.ant-input-affix-wrapper.ant-input-password span.ant-input-suffix {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.hint-text p{
  text-align: right;
  color: #ef4b25;
  padding-right: 20px;
  
}
.top-filter-part {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}
.search-part-top {
  flex: 1;
  /* max-width: 800px; */
  width: 100%;
}
.ant-form-item-label > label{
  color:#000;
  font-weight: 500;
  /* width: 126px; */
  margin-right: 10px;
}
.modalStyle .ant-form-item-label{
  text-align: left;
  color:#000;
  font-weight: 500;
}
.modalStyle .ant-col-xl-9{
  flex: 0 0 9.5% !important;
}
.modalStyle .ant-form-item-control.ant-col-xl-15{
  max-width: 89.5% !important;
}
.form-header-block{
  width:100%;
  margin-bottom: 10px;
}
.form-header-block h2{
  font-size: 20px;
  color: #888;
  font-weight: 600;
}
.form-header-block span{
  font-weight: 400;
  font-size: 16px;
  color: #58666d;
}
.form-bottom-block .ant-form-item-control-input-content {
  text-align: right;
}
.form-inner-control .ant-select {
  max-width: 82px;
}
.form-inner-control .ant-input-group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.form-inner-control .ant-input-group > input.ant-input {
  flex: 1;
  margin-left: 10px;
}
span.ant-input-group-addon{
  width: 70px;
  padding-left: 0;
  padding-right: 0;
  border: 0;
  background: no-repeat;
  border-bottom: 1px solid #b5b5b5;
  border-right: 1px solid #e5e5e5 !important;
}
.v-text{
  text-align: right;
  margin:0;
  font-weight: 600;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
  background-color: #fff;
}
.icon-with-edit-delete {
  cursor: pointer;
  margin-right: 12px;
  max-width: 17px;
}

.ant-table-thead tr th span {
  font-size: 13px;
}
.right-text {
    text-align: right !important;
}
.right-text  .ant-table-column-sorters{
  justify-content: flex-end !important;
}
.center-text {
  text-align: center !important;
}
.center-text  .ant-table-column-sorters{
justify-content: center !important;
}
.center-text .icons-list .icon-with-edit-delete:last-child{
  margin-right: 0;
}
.ant-btn:not(.ant-btn-link,.ant-btn-text),

.ant-btn-primary{
  text-transform: uppercase;
}
.loginlogo p.v-text {
  color: #000;
  margin-top: -12px;
  margin-right: 13px;
}
.search-block-main {
  max-width: 540px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.search-block-main .ant-input-search{
  flex:1;
}
.search-block-main .ant-input-group-addon{
  border:0 !important;
  width: 38px !important;
}
.search-block-main .ant-input{
  border:0;
  border-bottom:1px solid #b5b5b5;
}
.search-block-main .ant-input:focus{
  border-bottom:1px solid #0069ff;
  box-shadow: none;
}

.search-block-main .ant-input-group-addon .ant-input-search-button {
  background-color: #00ac1c;
  border-color: #00ac1c;
  padding: 0 7px;
  border-radius: 2px !important;
  font-size: 16px;
}
.search-block-main .ant-input-group-addon .ant-input-search-button:hover,
.search-block-main .ant-input-group-addon .ant-input-search-button:focus{
  background-color:#03a51d;
  border-color:#03a51d;
}
.close-btn-block{
  padding:0;
  
}
.close-btn-block span{
  font-size: 14px;
}
.advance-search-link, .advance-search-link span{
  text-decoration: underline;
  color: #888;
}
.login-user-detail {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.login-user-profile-img{
  width: 60px;
  text-align: center;
    position: relative;
    overflow: hidden;
}
.login-user-profile-img > input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 60px;
  height: 90px;
  opacity: 0;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -30px;
}
.login-user-profile-img img{
  width: 60px;
  height:60px;
  border-radius: 50%;
  object-fit: cover;
}
.login-user-profile-img span{
  display: block;
  text-align: center;
  font-size: 14px;
}
.logn-user-info{
  flex:1;
  padding-left: 11px;
}
.logn-user-info h3 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0 0 3px;
}
.logn-user-info p{
  font-size: 16px;
  color: #888;
  font-weight: 400;
  margin:0;
}
.search-block-main > a.ant-dropdown-trigger.ant-dropdown-link {
  font-size: 20px;
  color: #555;
  margin-top: 4px;
  margin-right: 9px;
}
.search-block-main > a.ant-dropdown-trigger.ant-dropdown-link span{
  padding-right: 13px;
  background-image: url(../images/down-blue.png);
  background-repeat: no-repeat;
    background-position: bottom right;
}
.ant-dropdown-menu-item{
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
}
.ant-dropdown-menu-item a{
  padding: 10px 12px;
    margin: -5px -12px;
}

li.ant-dropdown-menu-item .anticon {
  font-size: 14px !important;
}
.ant-dropdown-menu li:last-child{
  border-bottom:0;
}
.ck.ck-editor__editable_inline {
    min-height: 100px;
}
.left-text {
  text-align: left;
}


/*Gallery*/


.gallery-list-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.gallery-list-box{
max-width: 100%;
width: 100%;
display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 15px 10px;
}
.gallery-list-box-salesorder{
  max-width: 25%;
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 15px 10px;
  float: left;
}
.display-grid {
  display: grid;
}
.display-grid p{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.gallery-website-block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}
.gallery-list-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.gallery-list-block div[data-react-beautiful-dnd-droppable]{
  flex-wrap: wrap;
}
.gallery-list-block div[data-react-beautiful-dnd-droppable] div[data-react-beautiful-dnd-draggable]{
  max-width: 25%;
width: 100%;
}
.gallery-drag-option{
width: 32px;
display: flex;
flex-direction: column;
}
.gallery-drag-option a{
  margin-bottom: 0px;
  display: inline-block;
  font-size: 19px;
  color: #3300FF;
}
.gallery-drag-option a img{
width: 17px;
}
.gallery-img-block{
width: 143px;
position: relative;
/* background-color: #f9f9f9; */
border: 2px solid #f2f2f2;
}
.gallery-img-block:before{
  content: "";
  display: block;
  padding-top: 110%;
}
.gallery-img-block img{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.gallery-text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
}
.gallery-text-content .image-info{
width: 100%;
}
.gallery-text-content .image-info a{
font-size: 15px;
color: #000;
font-weight: bold;
}
.gallery-text-content .image-info span{
font-size: 13px;
color: #000;
width: 100%;
margin: 0;
line-height: normal;
margin-bottom: 10px;
display: inline-block;
}
.gallery-text-content .image-info p{
font-size: 13px;
color: #000;
width: 100%;
margin: 0;
line-height: normal;
}
.control-group{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.control-list-inn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}
.control-group .control-list-inn:last-child{
  margin-right: 0;
}
.control-list-inn span {
  width: 100%;
  font-size: 12px;
  color: #000;
  font-weight: 600;
  margin-bottom: 3px;
}
.file-upload-control .ant-upload.ant-upload-drag{
  background-color: #F9F9F9;
  border: 3px dashed #e2e2e2;
  margin-bottom: 20px;
}
.file-upload-control .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon{
  color: #697b8c;
}
.file-upload-control .ant-upload.ant-upload-drag .ant-upload-drag-container{
  padding-bottom: 20px;
  background-image: url(../images/bar-sign.png);
  background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 230px;
}
.file-upload-control .ant-upload.ant-upload-drag p.ant-upload-text{
  color: #748595;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 15px;
}
.file-upload-control {
  margin-top: 40px;
}
@media (max-width:1599px){
  .gallery-list-box{
    max-width: 100%;
  }
  .gallery-list-block div[data-react-beautiful-dnd-droppable] div[data-react-beautiful-dnd-draggable]{
    max-width: 33.33%;
  }
  .gallery-text-content{
    padding-left: 10px;
  }
  .control-list-inn{
    margin-right: 20px;
  }
  .gallery-img-block{
    width: 133px;
  }
}
@media (max-width:1199px){
  .gallery-list-box{
    max-width: 100%;
  }
  .gallery-list-block div[data-react-beautiful-dnd-droppable] div[data-react-beautiful-dnd-draggable]{
    max-width: 50%;
  }
  .gallery-text-content{
    padding-left: 10px;
  }
  .control-list-inn{
    margin-right: 20px;
  }
}
@media (max-width:991px){
  .gallery-list-box{
    max-width: 100%;
  }
  .gallery-text-content{
    padding-left: 10px;
  }
  .control-list-inn{
    margin-right: 20px;
  }
  .gallery-list-block div[data-react-beautiful-dnd-droppable] div[data-react-beautiful-dnd-draggable]{
    max-width: 100%;
  }
}

@media (max-width:575px){
  .gallery-img-block{width: calc(100% - 32px);}
  .gallery-text-content{flex:0 0 100%;padding-left: 32px;margin-top: 10px;}
  .gallery-img-block:before{padding-top: 100%;}
  .gallery-list-block div[data-react-beautiful-dnd-droppable] div[data-react-beautiful-dnd-draggable]{
    border-bottom: 1px solid #eee;
  }
}

/*--Class css start--*/
#class_attribute_list{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
#class_attribute_list label.ant-checkbox-wrapper {
  margin: 0 10px 10px 0;
  width: calc(25% - 10px);
}
.class_attributes .ant-tag-blue{
  margin-bottom: 5px;
}
/*--Class css end--*/

.w-100{
  width: 100% !important;
}
.d-flex{
  display: flex;
}
.justity-end{
  justify-content: flex-end;
}
.mobileNumber .ant-input-number{
  margin-top: 2px;
}
.mobileNumber .ant-input-number input{
  border-bottom-left-radius: 0!important;
}
.mobileNumber .ant-form-item-control-input-content .ant-select{
  width: 66px !important;
}
.mobileNumber .ant-select .ant-select-selector  {
  width: 66px!important;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 2px!important;
  border-top-right-radius: 0;
  border-right: 1px solid #e5e5e5!important;
}
.mobileNumber .ant-form-item-control-input-content{
  display: flex;
}
.DiscontinuedClaass .ant-select-selector{
  width: 79px!important;
}
/* .basicInformation .ant-collapse-content-box{
  padding-left: 0;
  padding-bottom: 0;
} */

.clickable-row .ant-table-row{
  cursor: pointer;
}
.expandable-table-sale-return table {
  table-layout: fixed !important;
  padding-top: 0px !important;
}
.expand-sales-receiving .ant-table {
  margin: 0px !important;
}
td:has(> .expand-sales-receiving) {
  padding: 0px !important;
}
.extand-table-col {
  padding-left: 4px !important;
}
.convert-qty-class {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.convert-qty-class-span {
  color: red;
  font-weight: bold;
  margin-top: -18px;  
}

.sales-return-cart tr.ant-table-expanded-row td,
.sales-return-cart tr.ant-table-expanded-row:hover td.ant-table-cell-row-hover,
.receiving-return-cart tr.ant-table-expanded-row td,
.receiving-return-cart tr.ant-table-expanded-row td.ant-table-cell-row-hover {
  background: #d3d3d3 !important;
}


.ant-pagination.ant-table-pagination{
  display: none;
}

/* .ant-table-body{
  max-height: calc(90vh - 170px);
} */
/* .ant-modal-title{
  color: red  !important;
} */

 .varificaton-alert .ant-modal-title{
  color: red !important;
} 
.cart-alert .ant-modal-title{
  color: #0069ff !important;
} 

.cart-alert .ant-modal-body {
  height: 500px; /* Fixed height */
  overflow: auto; /* Enable scrolling */
  text-align: center; /* Center text alignment */
  color: blue; /* Text color */
}

.modalStyle .ant-modal-content {
  position: relative;
  background: none;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.modalStyle .ant-modal-header{
  background-color:  #DDE2E4 !important;
  border-bottom: 1px solid #DDE2E4;
  border-radius: 2px 2px 0 0;
  font-family: "Lato",Arial,Helvetica,sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 26px !important;
  color: #252C32 !important;
  border-radius: 18px 18px 0px 0px;
}
.modalStyle .ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 10px;
  vertical-align: top;
}
.modalStyle .ant-note-modal-title{
  background-color: #DDE2E4 !important;
}
.modalStyle .ant-modal-body {
  text-align: left !important;
  padding: 0px 22px 1px 22px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  background-color: #DDE2E4 !important;
  border-radius: 0px 0px 18px 18px;
}
.modalStyle .ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 12px 0px 0px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.modalStyle .ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  margin-left: 10px;
}
.custom 
  .ant-collapse-content-box {
    margin-top: -2rem;
   
}
.addSales-order-table.ant-col-xxl-16 {
  display: block;
  flex: 0 0 61.666667%;
  max-width: 66.66666667%;
}
.breadcrumbcustom{
  margin-top: 1rem !important;
  margin-left: 1.5%;
  width: 92%;
}
/*Print Labels*/

.shelf-label{
  background: #fff;
  
  max-width: 380px;
  text-align: center;
  
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    padding: 5px 6px 0;
    box-sizing: border-box;
}
.shelf-label h1{
  font-size: 14px;
  text-transform: uppercase;
  color: #5b605a;
  font-weight: bold;
  margin: 2px 0 0;
  line-height: 1.1em;
}
.shelf-label h2{
  font-size: 12px;
  text-transform: uppercase;
  color: #5b605a;
  font-weight: bold;
  margin: 0;
  line-height: 1.3em;
}
.price-label h3{
  font-size: 8px;
  text-transform: uppercase;
  color: #5b605a;
  font-weight: bold;
  line-height: 1em;
  margin: 0;
}
.price-label h4{
  font-size: 10px;
  line-height: 1.1em;
  text-transform: uppercase;
  color: #5b605a;
  font-weight: bold;
  margin: 0;
}
.price-label h5{
  font-size: 8px;
  text-transform: uppercase;
  color: #5b605a;
  font-weight: bold;
  margin: 0;
  line-height: 1em;
}
.price-label h1.price{
  font-size: 20px;
  text-transform: uppercase;
  color: #5b605a;
  font-weight: bold;
  margin: 0;
  line-height: 1em;
  margin: 1px 0 -8px;
}
.word-break {
    word-break: break-all;
}
.barcode-with-price {
    display: flex;
        width: 100%;
  box-sizing: border-box;
    align-items: flex-start;
    margin-top: -5px;
}
.class-39 .barcode-with-price{
  margin-top: -3px;
  margin-bottom: -3px;
}
.barcode-with-price h4{
  margin-left: 10px;
}
.barcode-with-price span {
  flex: 1;
}
.barcode-with-price span img {
  width: auto;
  margin:0 auto -9px;
  max-height: 39px;
}
.class-39 .barcode-with-price span img {
  margin:0 auto;
}
.barcode-with-price h2 {
  margin-left: 0;
  margin-top: 6px;
  font-size: 11px;
}
.shelf-label.shelf-label-small {
  min-height: unset;
  padding: 5px 3px 4px;
}
.shelf-label img{
  max-width: 100%;
  margin:0 auto -6px;
}
.div-to-print .shelf-label {
  margin: 5px;
  max-width: 196px;
  width: 100%;
  margin-left: auto;
  display: inline-block;
  margin-right: auto;
}
.shelf-label.shelf-label-small{
  max-width: 150px;
}
.mini-test-left .barcode-with-price span img {
  margin-right: auto;
  margin-left: -17px;
}
.block-barcode{
  width: 100%;
  text-align: left;
  /* margin-left: -200px; */
  display: none;
}

.div-to-print{
  width: 100%;
  text-align: left;
}
.shelf-label.text-left {
  text-align: left;
  align-items: flex-start;
}
.price-label h4.box-code-variety-alias {
  margin-bottom: -8px;
  font-size: 12px;
}
.price-label.class-39 h4.box-code-variety-alias{
  margin-bottom: -3px;
  
}
/* .div-to-print {
  visibility: hidden;
  position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 0;
    width: 0;
} */

.price-label.class-39 h1.price {
  margin: 1px 0 -5px;
}
.price-label.class-39.shelf-label img {
  margin: 0 auto 0;
}
.pagebreak {
  clear: both;
  page-break-after: always;
  
}
@media print {
  body * {
    visibility: hidden;
  }
  .div-to-print * {
    visibility: visible;
  }
  .block-barcode{
    display: block;
  }
  #root{
    height: 0;
    width: 0;
    overflow: hidden;
    display: none;
  }
  
 
}  
.shelf-label.box-shelf-label {
  padding: 11px 6px 11px;
}
.shelf-label.box-shelf-label h2{
  line-height: 1.4em;
}

/*Print Labels end*/

/* .ant-space-item{
  margin-left: 3%;
} */

.judi {
  margin-left: 45px!important;
}
.add-level-button{
  width: 9rem;
}
.mb-10{
  margin-bottom: 10px;
}
/* .lable_to{
  margin-left: -15px!important;
} */
/* .w-250 .ant-form-item-control-input{
  width: 140px;
}
.w-250 .ant-input{
  width: 70px;
} */
.close-outlined-div{
  min-width: 21px;
}
/*w-250 .package-type{
   margin-left: 25px; 
} */
/* .count-field{
  margin-left: 1.5rem;
} */
.add-level-button{
  margin-left: .2rem;
}
.collapse-devider{
  float: right;
    /* margin-right: 1rem;
    margin-top: -33px; */
}.collapse-devider a{
font-weight: bold;
}
.collapse-d{
 margin-top: 0; 
}
/* .basic-information{
  width: 90%;
} */
.basic-information .ant-collapse-header{
  color: #888888!important;
    font-weight: bold;
    font-size: 18px;
    pointer-events: none;
}
/* .basic-information .ant-collapse-content-box{
  padding-left: 25px;
} */

/* .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  margin-left: -57px;
  margin-right: -484px;
} */
/* .backEnd-integration > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  margin-left: 0;
  margin-right: 0;
} */
.variety-model-title{
  text-align: center;
    font-size: 20px;
}
.site-card-border-less-wrapper {
  /* padding: 30px; */
  margin-left: 10rem;
  /* background:snow; */
  text-align: center;
}

/* customer css */

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
/* .customerrow{
  width: 100%;
  margin-top:-610px;
  margin-left: 20px;
}
.customerrow > .col-8{
  max-width:60%;
}
.customerrow > .col-4{
  max-width: 40%;
} */
/* .w-250count-field{
  margin-left:-30px;
} */

/* .di-250{
   margin-left: -60px; 
} */
.w-250 .ant-form-item-control {
  width: 70px;
}
.w-250.package-type .ant-form-item-control {
  width: 107px;
}
.close-outlined-div{
  margin-left: 20px;
}
.pl-1{
  padding-left: 1rem;
}
.pl-2{
  padding-left: 2rem;
}
.pl-3{
  padding-left: 3rem;
}
.pl-4{
  padding-left: 4rem;
}
.pl-05{
  padding-left: 0.5rem;
}
.pr-1{
  padding-right: 1rem;
}
.pr-05{
  padding-right: 0.5rem;
}
.pt-1{
  padding-top: 1rem;
}
.pb-1{
  padding-bottom: 1rem;
}
.pl-0{
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}

.customer_dropships{
  margin-left: .5rem;
}
.cust-type{
  margin-left: 1rem;
}
.panel-header{
  color: #888888!important;
    font-weight: bold;
    font-size: 1rem!important;
    margin: 1%;
}
.panel-header2{
  color: #888888!important;
    font-weight: bold;
    font-size: 30px!important;
}
.panel-header5{
  color: #888888!important;
    font-weight: bold;
    font-size: 20px!important;
}
.panel-header3{
  color: #888888!important;
    font-weight: bold;
    margin-left: .5rem !important;
    font-size: 15px!important;
}
.panel-header4{
  color: #888888!important;
    font-weight: bold;
     margin-left: .5rem !important;
    font-size: 15px!important;
}
.customer-lookup{
  text-align: center;
  /* margin-top: 100px; */
}
.ml-auto{
  margin-left: auto;
}
.text-end{
  text-align: right;
}
.mr-1{
  margin-right: 1rem;
}
.ml-1{
  margin-left: 1rem;
}
.mt-1{
  margin-top: 1rem;
}
.mb-1{
  margin-bottom: 1rem;
}
.mt-2{
  margin-top: 2rem;
}
.space-buttom{
  margin-bottom: 3rem;
}
.ml-4{margin-left:4rem;}

.edit-information{
position: absolute;
right: 0;
}
/* .vip-card .ant-form-item-label{
text-align: left;
} */
.vip-card .ant-card-body{
  padding: 11px 15px 15px !important;
}
.customer-right-col{
  padding-left: 16px;
}
.customer-right-col .ant-card .ant-card-head{
  padding: 0 15px;
}
.customer-right-col .ant-card .ant-card-head .ant-card-head-title{
  padding: 15px 0;
}
.customer-right-col .ant-card-body{
  padding: 15px 15px 2px;
}
#content2::before{
  content: "\a";
  white-space: pre;
}

.temp-bold {
  font-weight: bold !important;
}
.ant-menu-icon {
  width: 20px;
  /* width: 22%;
  margin-top: -10%;
  margin-right: 5%; */
}
.dropship-req {
  color: red;
  margin-right: .6rem;
  font-weight: bold;
  margin-top: 1rem;
}
.ant-btn-sm {
  height: 24px!important;
  padding: 0px 7px!important;
  font-size: 12px;
}
.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
    border-left: 1px solid #f0f0f0;
    border: 1px solid #f0f0f0;
}
.datepiker{
  border: 0;
  border-bottom: 1px solid #b5b5b5;
  box-shadow: none !important;
  outline: none !important;
}
.line-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-line;

}
.set-margin{
  margin-bottom: -45px !important;
}
.info-tab>.ant-tabs-nav {
  width: 360px;
}

.search-part-top .form-layout-control .ant-select.ant-select-show-search.dropdown-override-pricing-form {
  width: 320px !important;
}

.ant-form-item-control-input-content .ant-select.dropdown-override-all-modules {
  width: 100% !important;
}
.ant-menu-icon-right{
  width: 20px;margin-left: 7px;
}
.ant-menu-icon-left{
  width: 20px;margin-right: 7px;
}



/* .info-tab > .ant-tabs-tab .ant-tabs-tab-active{
  border: o !important;
  border-top: 0px solid #b5b5b5 ;
  border-bottom: 0px solid #b5b5b5 ;
  border-left: 0px solid #b5b5b5 ;
} */
.item-bold>.ant-form-item-label>label {


    font-weight: 700 !important;

}
.rightside-purchased{
  position: fixed !important;
  z-index: 999;
  transform: rotate(270deg) translateY(-50%);
  right: -108px !important;
  top: 44%;
  margin: 0 !important;
}
.rightside-notes{
  position: fixed !important;
  z-index: 999;
  transform: rotate(270deg) translateY(-50%);
  right: -39px !important;
  top: 70%;
  margin: 0 !important;
}
.shipping-change{
  margin-top: .4rem !important;
}
.taj{
  width: 22% !important;
  margin-top: -8%;
  margin-right: 5%;
}

.ant-divider-horizontal{
  margin: 12px 0 !important;
}
.delete-top-filter .top-filter-part{
  display: none !important;
} 
.brd-card{
  border:1px solid #bcbcbc !important;
}
.sales-order-margin{
  margin-left: .3rem  !important;
}
.bradcrumb-card-text{
  width: 60%;
  text-align: end;
}
.label-color{
  font-size: 15px;
  color: #888888;
}
.item-checkbox{margin-left: 1rem;}
.breadcrumb-title{
  font-size: 15px;
  font-weight: 600;
}
.card-border{
  border: 1px solid #c4c4c4;
}
.card-body{
  width: 91%;
}
.setbarcode-design{
  border: 1px solid #888888 !important;
}
.set-Addselect {
  margin: 1% 5% !important;
  float: right !important;
}
/* .card-instruction{
margin-bottom: 2%;
} */
.shopping-cart-table .ant-table-wrapper{
  max-width: 94% !important;
}
.hideShowBtn{
  float: right;
  margin-left: 3% ;
}
.set-row-top{
  margin-top: 2% ;
}
.infoIcon {
  color: "red";
  width: "40px";
  float: "right"
}
.set-checkout{
  float: 'right';
  margin-top: 3%;
  margin-right: 7%;
}
.payment_method_dropdown{
  width: 16rem !important;
}
.top-spacing-changes{
  margin-bottom:-1.5rem !important;
}

.setbarcode-design .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  
  border:0!important;
  
}

.setbarcode-design .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border:0!important;  
}
.vipLevel{
  margin-left: -.7rem;
}
.shipping-class label{
  margin-right: 1px!important;
}
.checkout-form .ant-form-item{
  margin: 0 !important;
}
.checkout-form .ant-form-item-label label{
  margin: 0 !important;
}
.addressStreet{
  margin-left: 3.1%;
}
.addressStateCity{
  margin-left: 1.2%;
}
.expirationdate{
  margin-left: 2%;
}
.payment-type{
  margin-left: 4.1%;
}
.default-switch{
  margin-left: 2.5%;
}

.expirationdate .ant-form-item-control{
  margin-left: 8%;
}
/* .addresscity{
  margin-left: 2.5%;
} */
/* .arrow-hide .input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} */
.setAlign{
  margin-right: 10% !important;
}

/* .ant-select-single.ant-select-show-arrow , .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  width: 200px !important;
} */
.imputborder-remove{
  border-top: none ;
  border-left: none ;
  border-right: none ;
}
.right-text-shipping{
  margin-left: 30%;
}
.number-right .ant-input-number-input-wrap .ant-input-number-input{
  text-align: center !important;
  box-sizing: border-box !important;
}
/* .ant-menu-icon-taj{
height: 50%;
width: 30%;
} */
.reArrengePopup{
  margin-top: 7%;
}
.right-centre{
  text-align: center !important;
}
.Qty-centre{
  text-align: center !important;
}
.right-text-secondary{
  margin-left: 8% ;
}
.inventoryAdjustmentsList .top-filter-part{
  display: none !important;
}
.modal-text{
  color:#7ba730 !important;
}
.doller-sign{
  margin-left: 10px;
    margin-right: -16px;
}
.imputborder-remove .ant-input-number-sm input{
  height: 32px!important;
}
.imputborder-remove{
  width: 128px;
}
.imputborder-removes{
  border-top: none ;
  border-left: none ;
  border-right: none ;
}
.inventoryList-export{
  margin-left: 90%;
}
.placeorder-pagesection{
  margin-top: .1rem;
  margin-bottom: 1rem;
}
.inventorySubmit{
  margin-right: 1% !important;
}
@media (max-width: 1200px){
  /* .ant-form-item-label{
    text-align: left;
  } */
  .ant-form-item-label>label{
    font-size: 13px;
  }
}

.net_input{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.net_input input{
  flex: 1;
  margin-left: 6px;
}

.table-border {
  border:1px solid #eee;
  padding-bottom: 15px;
}  
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.invoice_total_qty {
  padding-left: 25px;
}
.error-msg {
  color: red;
  
}
.free-item-table {
  margin-top: 20px;
}
.hide-autoincrement .ant-input-number-handler-wrap {
  display: none !important;
}
.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-menu-submenu ul li{
  padding-left: 32px !important;
  padding-right: 10px !important;
  flex-wrap: wrap;
}
.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-menu-submenu ul li a {
  display: inline-block;
  width: calc(100% - 30px);
  white-space: initial;
  line-height: 18px;
  margin: auto;
}
.ant-form-inline .ant-form-item > .ant-form-item-label{
  flex: unset;
}
.basic-information{
  position: relative;
}
.open-close-text {
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 1;
  font-weight: 800;
}
.basic-information .ant-collapse-content-box{
  padding-left: 0;
  padding-right: 0;
}
.classes-from {
  max-width: 1200px;
}
.class_name_input{
  max-width: 525px;
}
.relationship-block{
  padding-left: 9%;
}
.adjusment-daterange{
  width: 100%;
}
.ant-table-header {
  padding-right: 15px;
  background-color: #fafafa;
}
.inventory_adjustment_table {
  margin-bottom: 20px;
}
.mr-0{
  margin-right: 0 !important;
}
.ml-0{
  margin-left: 0 !important;
}
.mb-0{
  margin-bottom: 0 !important;
}
.activity-list{
  display: flex;
  flex-wrap: wrap;
}
.activity-list span:not(:first-child){
  flex:1;
}
.activity-list span[role="img"]{
  margin-top: 5px;
}
span.customer-special-instruction {
  margin-right: 19px;
  display: inline-block;
}
.white-space-intial{
  white-space: initial;
}
.ant-picker {
  width: calc(100% - 15px);
}
.receiving-row-filter .ant-picker {
  width: 100%;
}
.tax-form {
  padding: 15px 0 0;
}
.price-rule-form .ant-picker {
  width: 100%;
}
.info-tab .ant-tabs-tab-btn{
  text-align: left;
}
.ant-tabs.info-tab{
  margin-top: 15px;
}
.card-type-text{
  color:#000;
  margin-top:-1px;
  font-weight: bold;
  margin-right: 5px;
}
.barcode-item-card {
  padding: 2px 10px 10px;
  border: 1px solid #b5b5b5;
}
.barcode-text-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.sz-sidemenu {
  max-width: 215px;
  min-width: 215px;
}
.barcode-text-block img {
  margin-left: 10px;
}
.vip-card img {
  padding-right: 5px;
  padding-bottom: 5px;
}
.check-detail-info .ant-picker{
  width: 100%;
}
.shipping_entry_form .ant-form-item-label > label {
  height: 40px;
  white-space: normal;
  line-height: 1.3em;
}
.ck.ck-content {
  max-height: 200px;
  overflow-y: auto;
}
.link-box_n{
    background-color: #f5f5f5;
    display: inline-block;
    padding: 6px 8px;
    margin: 10px 0px 0 10px;
    text-decoration: underline;
    width: 100%;
    text-align: center;
}
.print-radio {
  min-width: 140px;
}
@media (min-width: 1200px) {
	.customer-left-block {
		max-width: calc(100% - 300px);
	}
}
@media (min-width: 1920px) {
  .invoice_total_qty {
    padding-left: 75px;
  }
}
@media (max-width:1280px){
  #customer-form .ant-form-item-label > label{
    font-size: 13px;
    margin-right: 2px;
  }
  span.customer-special-instruction {
    margin-right: 11px;
  }
  .tax-form .ant-form-item-label > label{
    margin-right: 3px;
  }
  .info-tab>.ant-tabs-nav{
    width: 330px;
  }
}
@media (max-width:1024px){
  .relation-lable-block .ant-col.ant-form-item-label {
    text-align: right;
}
.relation-lable-block .ant-col.ant-form-item-label label {
    margin-right: 20px;
}
.info-tab>.ant-tabs-nav {
  width: 100%;
}
.info-tab {
  flex-wrap: wrap;
}
}
@media (min-width:1200px){
  .horizontal-divider{display: none;}
}
@media (max-width:1200px){
  .tablet-none{
    display: none;
  }
  .adj-button-group button.ant-btn {
      margin: 0 0 0 10px;
  }
  .info-tab .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active{
    border: 1px solid #e5e5e5 !important;
    border-bottom:1px !important;
  }
  
}
@media (max-width:1024px){
  .ant-layout-sider{
    max-width: 80px !important;
    flex: 80px !important;
  }
  .search-part-top{
    flex:0 0 100%;
  }
  .add-button-right{
    width: 100%;
  }
  .exclude-search-btn .search-part-top {
      flex: 1;
  }
  .exclude-search-btn .add-button-right{
    width: auto;
  }
  .ant-table-body{
    overflow-x: auto !important;
  }
  .ant-table-body table{
    min-width: 990px;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 10px 10px;
  }
  .customer-so-balance {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 253px;
  }
  .customer-right-col{
    position: unset;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex:0 0 100% !important;
    padding-bottom: 20px;
  }
  .customer-right-col .ant-card{
    margin-left: auto !important;
    margin-right: auto !important;
    width: calc(50% - 20px);
  }
  .customer-layout{
    padding-top: 82px;
    position: relative;
  }
  .customer-so-balance .ant-row div[class*="ant-col-"]:not(:first-child) button.ant-btn.ant-btn-primary {
      margin-left: 10px;
  }
  .vip-card .ant-form-item-label{
    text-align: right;
  }
  .line-break-out{
    white-space: unset;
  }
  .customer-bottom-btn .ant-form-item-control-input-content {
    text-align: right;
  }
  .customer-bottom-btn .ant-form-item-control-input-content .ant-btn {
    margin-right: 10px;
  }
  .gallery-list-box-salesorder{
    max-width: 33.33%;
    width: 100%;
  }
}
@media (max-width:991px){
  .margin-b-sm{
    margin-bottom: 10px;;
  }
  .search-block-main{
    max-width: 420px;
  }
  .top-right-logo img{
    max-width: 160px;
  }
  .top-filter-part{
    margin-bottom: 10px;
  }
  #class_attribute_list label.ant-checkbox-wrapper{
    width: calc(33.33% - 10px);
  }
  #class_attribute_list label.ant-checkbox-wrapper span{
    font-size: 14px;line-height: 1.2em;
  }
  .inventoryList-export{
    margin-left: 0;
  }
  body .form-layout-control .edit-information{
    position: relative;
    float: right;
    margin-right: 0;
  }
  .edit-content{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .cust-phone span.ant-input-group-addon{
    width: 59px;
  }
  .cust-phone span.ant-input-group-addon .ant-select-selector{
    padding: 0 3px;
  }
  .input-file{
    transform: scale(0.9);
    transform-origin: left;
  }
  .add-addtional-content-block {
      flex-direction: column-reverse;
  } 
  .gallery-list-box-salesorder{
    max-width: 50%;
    width: 100%;
  }
}
.ant-popover-inner-content {
  max-height: calc(100vh - 50px);
  overflow: auto;
}
.bottom-block-info .text-lable {
    text-align: right;
    width: 100%;
    display: inline-block;
    padding-right: 20px;
    font-size: 14px;
    color: #000;
}
.price-to-be-block-main .ant-form-item-label {
  text-align: left !important;
}
.background-quantites{
  background: yellow;
  display:block;
  width: 70%;
  margin-left: 17%;
}

.payment-status .ant-form-item-label label {
  color: #888888!important;
  font-weight: bold;
  font-size: 1rem!important;
  margin: 1%;
}
.payment-status {
  padding: 0.5rem 0 1rem;
}

@media screen and (max-width: 767px) {
  .payment-status {
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .payment-status, .status-update {
    padding: 0;
  }
}

.table-row-light {
  background-color: #d5ebda

}
.table-row-light-invoice{
  background-color: #f2c4c4
}

.table-row-dark {
  background-color: #ead3d4

}

.table-row-yellow {
  background-color: #fafaeb
}

.pickup-delivery-box {
  margin-top: 30px;
}

.border {
  border: 1px solid #eee;
}

.invoice-box {
  padding: 30px 0px 40px;
}

.invoice-detail, .ship-detail, .contact-detail {
  padding: 0px 15px;
  /* word-break: break-all; */
}

.boxes {
  padding: 30px 0;
}
.box-detail {
  padding: 0px 15px;
}
@media (max-width: 767px) {
  .invoice-detail, .ship-detail {
    padding-bottom: 15px;
  }
}

.hidestep .ant-input-number-handler-wrap {
  display: none;
}

.table-row-light {
  background-color: #d5ebda

}

.table-row-dark {
  background-color: #ead3d4

}

.pickup-delivery-box {
  margin-top: 30px;
}

.pickup-delivery-box {
  margin-top: 30px;
}

.border {
  border: 1px solid #eee;
}

.invoice-box {
  padding: 30px 0px 40px;
}

.invoice-detail, .ship-detail, .contact-detail {
  padding: 0px 15px;
  /* word-break: break-all; */
}

.boxes {
  padding: 30px 0;
}
.box-detail {
  padding: 0px 15px;
}
@media (max-width: 767px) {
  .invoice-detail, .ship-detail {
    padding-bottom: 15px;
  }
}

.hidestep .ant-input-number-handler-wrap {
  display: none;
}

.picking-block .ant-row.ant-form-item {
  margin-bottom: 10px;
}

/* Sales and refund CSS start */
.button-link, .button-link a:hover {
  text-decoration: underline;
}
.filter-bar {
  margin: 10px 0 20px;
}
.return-status .ant-checkbox-group-item {
  margin-right: 5px;
}
form#returns .ant-row {
  margin-bottom: 0px;
}
.contact-details {
  margin-top: 5px;
}
.add-more-btn {
  width: 100%;
  text-align: right;
  clear: both;
  margin: 0;
}
.return-ship-method .ant-form-item-label > label {
  align-items: baseline;
}
.return-panel .ant-collapse-header, .return-panel .ant-collapse-content-box, .add-new-item .ant-collapse-header {
  padding: 0 !important;
}

.deduction-reason {
  padding-top: 16px;
  padding-left: 10px;
}
.calculate {
  padding-left: 17px;
}
.bottom-buttons .ant-btn {
  margin: 10px 0px 10px 15px;
}
.mt-2 {
  margin-top: 2rem;
}
.view-sz-logo {
  max-width: 44%;
}
.view-return-divider {
  display: none;
}
.sales-return-spin .ant-spin, .sales-receiving-spin .ant-spin, .sales-refund-spin .ant-spin, .view-sales-return-spin .ant-spin, .general-spin .ant-spin {
  max-height: fit-content !important;
}
.add-new-item .ant-collapse-header {
  max-width: fit-content;
}
.add-new-item .ant-collapse-content-box {
  padding: 16px 0;
}
.receiving-barcode {
  border: 1px solid #888888;
  max-width: 45%;
}
.receiving-barcode .ant-input-group-addon {
  border: 0;
  cursor: pointer;
  border-left: 1px solid #e5e5e5;
}
.refund-total-detail {
  padding: 20px 40px 20px 20px;
}
.refund-payment-detail {
  padding-top: 20px;
}
.payment-radio .ant-radio-wrapper{
  font-size: 16px;
  margin-bottom: 5px;
  align-items: center;
}
.refund-clear-btn {
  border: none;
  margin: 0px;
  padding: 0px !important;
}
.refund-canvas {
  border: 1px solid #000;
}

@media screen and (max-width: 1599px) and (min-width: 1200px) {
  .receiving-barcode {
    max-width: 60%;
  }
}
@media screen and (max-width: 1599px) and (min-width: 768px) {
  .row-filter {
    margin-top: 20px;
  }
  .calculate {
    padding-left: 16px;
  }
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .receiving-barcode {
    max-width: 70%;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .row-filter, .date-row-filter, .row-history-filter {
    margin-top: 10px;
  }
  .bottom-part {
    margin: 10px 0 20px;
  }
  .view-sz-logo {
    max-width: 70%;
  }
  .receiving-barcode {
    max-width: 90%;
  }
  .store-info {
    margin-top: -30px;
  }
  .refund-total-detail {
    padding: 20px 10px 20px 0px;
  }
}
@media screen and (max-width: 767px) and (min-width: 576px) {
  form#returns .ant-row {
    margin-bottom: 15px;
  }
  .search-field {
    margin-bottom: 10px;
  }
  .contact-row {
    margin-bottom: 5px;
  }
  .calculate {
    padding-left: 20px;
  }
  .deduction-reason {
    padding: 0px;
  }
  .bottom-part {
    margin: 10px 0 20px;
  }
  .view-sz-logo {
    max-width: 100%;
  }
  .view-return-divider {
    display: block;
  }
  .receiving-barcode {
    max-width: 90%;
    margin-bottom: 10px;
  }
  .refund-total-detail {
    padding: 0px;
  }
}
@media screen and (max-width: 575px) {
  form#returns .ant-row {
    margin-bottom: 5px;
  }
  form#returns .filter-button {
    margin-top: 10px;
  }
  .search-field {
    margin-bottom: 10px;
    width: 100% !important;
  }
  .return-form-item .ant-form-item-label, .return-form-item .ant-form-item-control, .return-ship-method .ant-form-item-label {
    padding-bottom: 0px;
  }
  .return-form-item .ant-form-item-label > label {
    font-weight: bold;
  }
  .contact-row {
    margin-bottom: 5px;
  }
  .customer-pay-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .customer-pay-toggle span {
    padding-right: 10px;
  }
  .return-ship-method {
    margin-bottom: 10px;
  }
  .bottom-part {
    margin: 10px 0 20px;
  }
  .calculate {
    padding-left: 0px;
  }
  .calculate input {
    width: 45px;
  }
  .deduction-reason {
    padding: 0px;
  }
  .return-filter-form .ant-form-item .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .view-sz-logo {
    max-width: 80%;
  }
  .view-return-divider {
    display: block;
  }
  .receiving-barcode {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .refund-total-detail {
    padding: 0px;
  }
}
/* Sales and refund CSS end */

.faxNumber .ant-input-number{
  margin-top: 2px;
}
.faxNumber .ant-input-number input{
  border-bottom-left-radius: 0!important;
}
.faxNumber .ant-form-item-control-input-content .ant-select{
  width: 66px !important;
}
.faxNumber .ant-select .ant-select-selector  {
  width: 66px!important;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 2px!important;
  border-top-right-radius: 0;
  border-right: 1px solid #e5e5e5!important;
}
.faxNumber .ant-form-item-control-input-content{
  display: flex;
}

.customer-user-spin {
  max-height: fit-content !important;
}
.customer-user-spin {
  max-height: fit-content !important;
}

.col-header-center .ant-table-column-sorters {
  justify-content: center !important;
}
.background-quantites{
  background: yellow;
  display:block;
  width: 70%;
  margin-left: 17%;
}
.demo-container {
  width: 100%;
}

.progress-bar {
  height: 8px;
  background-color: rgb(206 79 5 / 32%);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(233 79 33);
  animation: indeterminateAnimation 0.5s infinite linear;
  transform-origin: 0% 50%;
}

.loader-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}



.my-store-barcode .ant-input {
  border-right: none;
}
.my-store-barcode .ant-input-group-addon {
  border: 1px solid #e5e5e5 !important;
}
.pad-16-0{
  padding: 16px 0px !important;
}
.reports-spin-loader{
  display:flex !important;
  justify-content:center !important;
  align-items:center !important;
  height:100vh !important;
}

.customerTaxInfo_modal .ant-modal-body {
  max-height: 400px; /* Adjust the max height as needed */
  overflow-y: auto;
}

.customerTaxInfo_modal .ant-select-dropdown {
  position: fixed !important;
}  
.table-row-red-pricing {
  color: red;
}

/* permission table design bug changes */
.module-permission .ant-table-header colgroup col, .module-permission .ant-table-body colgroup col {
  width: 150px!important;
  min-width: 150px!important;
}
.module-permission .ant-table-header colgroup col:first-child, .module-permission .ant-table-body colgroup col:first-child {
  width: 300px!important;
  min-width: 300px!important;
}

/* sales-by-item-detail report table design bug changes */
.sales-by-item-detail .ant-table-header colgroup col, .sales-by-item-detail .ant-table-body colgroup col {
  width: 120px!important;
  min-width: 120px!important;
}
.sales-by-item-detail .ant-table-header colgroup col:first-child, .sales-by-item-detail .ant-table-body colgroup col:first-child {
  width: 120px!important;
  min-width: 120px!important;
}
/* in listing page sort icon design issue*/
.ant-table-column-sorters{
  display: inline-block;
}
/*sub menu icon display center*/
.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-menu-submenu ul li span{
  display: flex;
}
/* payment portal display input type display in alignment*/
.doller-div .ant-form-item-control-input-content{
  display: flex;
}

.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.marketing-menu span.ant-menu-title-content {
  display: contents;
}

.ant-menu-icon.marketing-icon {
  width: auto;
  margin-right: 3px;
  max-width: 30px;
}

.ant-input-number-disabled {
  background-color:#f5f5f5;
  padding: 0;
}
/* payment portal display input type display in alignment in cheque data type*/
.cash-amount-main .ant-form-horizontal .ant-form-item-label {
  display: flex;
}

.float-right-margin-10 {
  float: right;
  margin-right :15px;
  font-weight: bold;
}

.error-color{
  color: #ff4d4f;
}
.negative-item-qty-row-color{
  background-color:#d6c7c4;
}

.mergeInventory .ant-form-vertical .ant-form-item-row {
  flex-direction: row;
}

.mergeInventory .ant-form-vertical .ant-form-item .ant-form-item-control{
  width: calc(100% - 200px);
  display: inline-block;
}

.mergeInventory .ant-form-vertical .ant-form-item-label{
  width: 200px;
}

.checkboxSelectAllMerge{
  text-align: center;
  margin-top: -18px;
}

.checkboxSingleMerge{
  text-align: center;
  margin-top: 22px;
}

.checkboxUMTypeMerge {
  text-align: center;
  margin-top: 110px;
}

.mergeTopHead .ant-card-body {
  text-align: center;
  font-size: large;
  padding: 1px 0px 0px 1px;
}

.mergeForm .ant-form-item {
  margin-bottom: 5px;
}

.updateByMerge {
  text-align: end;
  margin-right: 65px;
  font-size: 16px;
}

.deleteCheckboxProduct{
  margin-top: 0px !important;
}

.deleteCheckboxItem{
  margin-top: 10px !important;
}


.urgent_note_customer{
  padding: 0px 0px 10px 0px;
}

.urgent_note_customer .lable_notes{
  cursor: pointer;
  color: #1890FF;
}